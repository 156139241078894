<template>
  <div class="indexNews">
    <router-view />
     <footerCard class="footer"></footerCard>
  </div>
</template>
<script>
import footerCard from '../../components/footerCard.vue'

export default {
  data() {
    return {

    };
  },
  methods: {

  },
  components:{
    footerCard
  },
}
</script>
<style scoped lang="scss">
.indexNews {
   background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  .footer{
    display: block;
    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}
</style>